.yc-container .react-calendar {
    width: 100%;
    margin: auto;
}

.yc-container .green-bg {
    background-color: rgb(110, 193, 110, 0.2);
}

.yc-container .purple-bg {
    background-color: rgb(118, 119, 192, 0.5);
}

.yc-container .red-bg {
    background-color: rgb(223, 0, 0, 0.2);
}

.yc-container .grey-bg {
    background-color: rgb(211, 211, 211);
}

.calendar .block-range{
    display: flex;
    flex-flow: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 95%;
    font-weight: 700;
}

.calendar .block-range > * {
    margin-right: 20px;
}

.calendar .block-range .MuiCheckbox-root {
    color: #f50057;
}

.calendar .color-coding {
    margin-top: 10px;
    display: flex;
    flex-flow: row;
    width: 95%;
    justify-content: space-around;
}

.calendar .block-instructions {
    padding: 70px 15px 5px 0px;
    font-weight: 500;
    text-align: center;
    width: 95%;
}

.calendar .reservation-link {
    display: inline;
    margin-left: 5px;
}
.calendar .reservation-link:hover {
    cursor: pointer;
}
