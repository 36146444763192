.yc-container .modal {
    outline: 0;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.modal .spinner {
    margin: 50% 50%;
}