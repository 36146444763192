.price-input-container .title {
    font-weight: 900;
}

.price-input-container .reservation-price .price-input {
    margin-right: 25px;
}
.price-input-container .reservation-items .item-name {
    left: 15px;
}
.price-input-container .reservation-items .item-value {
    left: 35px;
}
.price-input-container .item-type {
    display:block;
    margin-left: 20px;
}

.price-input-container .reservation-items .add-more-button {
    border-radius: 50%;
    min-width: 0px;
    left: 20%;
}