.settings-container {
    margin: 0px 15px;
}

.settings-container .items {
    margin: 15px 0;
    display: flex;
    flex-flow: row;
    justify-content: space-between;
}


.settings-container .title {
    font-weight: 700;
    margin: 0 0 5px 5px;
}
