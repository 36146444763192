.yc-container .notice {
    text-align: center;
    text-decoration: overline;
}

.item-separation {
    margin-top: 50px !important;
}

.greeting {
    font-size: large !important;
    background-color: #3f51b5 !important;
    color: #fff !important;
    padding: 25px 0 !important;
    text-align: center;
    margin-bottom: 20px;
}
