.guestline-container {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    padding-top: 5px;
    padding-bottom: 5px;
}
.guestline-container .guest-label {
    min-width: 150px;
}

.guestline-container .guest-label-info {
    right: 50px;
    top: 15px;
}