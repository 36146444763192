.picture-container {
    display: flex;
    flex-wrap: nowrap;
    min-width: 355px;
    background-color: #fafafa;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 0 25px;
}

.picture-container button.submit {
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    outline: 0;
    background: #3f51b5;
    border: 0;
    padding: 10px 16px;
    margin-left: 10px;
    color: #FFFFFF;
    font-size: 14px;
    -webkit-transition: all 0.3 ease;
    transition: all 0.3 ease;
    cursor: pointer;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .picture-container .buttons {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: space-between;
    align-content: center;
    width: 90%;
  }

  .picture-container button.disabled {
    opacity: 0.5;
  }

  .picture-container .MuiTextField-root {
      width: 70%;
  }

  .avatar-input {
    visibility: hidden;
  }

  .picture-container .avatar-container {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: 20px;
  }

  .picture-container .avatar-button {
    color: #3f51b5;
    margin-top: 10px;
  }

  .picture-container .avatar-button:hover {
    cursor: pointer;
  }

  .picture-container .rotate:hover {
    cursor: pointer;
    background-color: rgb(231, 229, 229);
    border-radius: 50%;
  }

.picture-link-hidden {
  display: none;
}  

img.avatar {
    border-radius: 50%;
    margin: 10px;
}

a.sign-in {
    margin-right: 10px;
    color: rgb(240, 15, 139);
    text-decoration: inherit;
}

.greeting {
  font-size: large !important;
  background-color: #3f51b5 !important;
  color: #fff !important;
  padding-bottom: 20px !important;
}

.MuiList-padding {
  padding-top: 0 !important;
}

.picture-container .caption-container {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  padding: 30px;
}

  .picture-container .caption-container .location{
    margin-top: 20px;
  }