.features-container .MuiTextField-root {
    margin-right: 15px;
}

.accordion-header  > div {
    display: flex;
    justify-content: space-around;
    flex-flow: row;
}

.basics-container {
    width: 100%;
    padding: 20px;
}

.basics-container .basics {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    flex-wrap: wrap; 
}

.basics-container .basics#simple-tabpanel-0 >div {
    min-width: 50%;
}

.basics-container .basics .item {
    margin-right: 20px;   
}

.basics-container .MuiFormGroup-root {
    display: block;
}

.rules-container {
    width: 100%;
    padding: 20px;
}

.rules-container .rules {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.rules-container .rules .rule {
    margin-right: 20px;
    margin-bottom: 20px;
    min-width: 150px;
}

.rules-container .rules .rule .title {
    font-weight: 600;
}

.rules-container .rules .rule .MuiFormGroup-root {
    display: block;
}

.rules-container .rules.row1 {
    margin-bottom: 20px;
}

.spaces-container {
    width: 100%;
    padding: 20px;
}

.spaces-container .spaces {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.spaces-container .spaces .space {
    min-width: 300px;
    margin-bottom: 20px;
}

.spaces-container .spaces .space .MuiFormControlLabel-label {
    font-weight: 600;
}

.spaces-container .spaces .space .count {
    top: 6px;
}

.spaces-container .spaces .space .important {
    margin-top: 20px;
}

.spaces-container .spaces .space .info {
    margin-top: 20px;
}

.amenities-container {
    width: 100%;
    padding: 20px;
}
.amenities-container .amenities {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.amenities-container .amenities .amenity {
    min-width: 300px;
    margin-bottom: 20px;
}

.amenities-container .amenities .amenity .MuiFormControlLabel-label {
    font-weight: 600;
}

.amenities-container .amenities .amenity .count {
    top: 6px;
}

.amenities-container .amenities .amenity .important {
    margin-top: 20px;
}

.amenities-container .amenities .amenity .info {
    margin-top: 20px;
}

.pricing-container {
    width: 100%;
    padding: 20px;
}
.pricing-container .prices {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    flex-wrap: wrap;
}

.pricing-container .prices .price {
    margin-right: 20px;
    margin-bottom: 20px;
    min-width: 120px;
}

.pricing-container .prices .price .title {
    font-weight: 800;
}

.pricing-container .prices .price .coupon-discount {
    margin-top: 20px;
}

.pricing-container .currencies {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 25px;
}

.pricing-container .currencies .currency {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.pricing-container .currencies .currency .delete-icon {
    border-radius: 50%;
    background-color: #f9f5f5;
    margin-right: 8px;
    opacity: 0.8;
    z-index: 900;
  }
  .pricing-container .currencies .currency .delete-icon:hover {
    cursor: pointer;
  }
  .pricing-container .currencies .currency .non-delete-icon {
    border-radius: 50%;
    background-color: #f9f5f5;
    margin-right: 8px;
    opacity: 0.3;
    z-index: 900;
  }
  .pricing-container .currencies .currency .non-delete-icon:hover {
    cursor:not-allowed;
  }
