.no-property {
    text-align: center;
    margin-top: 50px;
    font-size: large;
}

.no-property > div {
    margin-bottom: 25px;
}

.home_container {
    width: 100%;
}

.yc-container .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    padding: 5px 10px;
}

.yc-container .content > * {
    min-width: 0;
    padding: 0;
}

.yc-container .content .name {
    font-size: 18px !important;
    font-weight: 600 !important;
}

.yc-container .content .main-features {
    font-size: 12px !important;
    font-weight: 400 !important;
}
