.user-list {
    margin-top: 40px;
}

table {
    margin-top: 20px;
    width: 99%;
    border: 1px solid lightgrey;
    z-index: 1;
    box-shadow: 4px 4px lightgrey;
    margin: auto;
}

table td, th {
    padding: 15px;
}

table thead {
    background-color: #a3acdb;
    color: #fff;
}

table button {
    padding: 10px 20px;
    border-radius: 20%;
}

table button:hover {
    cursor: pointer;
    background-color: #3f51b5;
    color: #fff;
}

.alternate {
    background-color: rgb(230, 227, 227);
}