.reservation-container {
    min-width: 355px;
    background-color: #fff;
    border: 1px solid #a0a096;
    width: 95%;
    padding: 15px;
    margin: 10px auto;
}

.reservation-amounts-container {
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
    flex-wrap: nowrap;
}

.reservation-divider {
    margin: 10px 10px 10px 10px;
}

.reservation-reserve-button {
    margin-top: 15px !important;
}

.yc-container .color-pink {
    color: pink;
}

.page2-container {
    display: flex;
    flex-flow: column nowrap;
}

.page2-container .buttons {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
}

.pay-method-label {
    margin-bottom: 12px;
}
.hide-text {
    color: #fff !important;
}

.reservation-details-container {
    width: 80%;
    border: 1px solid rgb(207, 206, 206);
    box-shadow: 5px 10px rgb(207, 206, 206);
    margin: auto;
    padding: 10px;
    max-width: 300px;
}

.reservation-details-dates {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: nowrap;
}

.reservation-details-change {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
}

.reservation-details-dates > div {
    background-color: rgb(238, 237, 237);
    padding: 5px;
}

.reservation-details-guests, .reservation-details-price {
    text-align: center;
    margin-top: 10px;
}

.reservation-details-guests {
    display:flex;
    flex-flow: row nowrap;
    justify-content: space-evenly;
}
.reservation-details-items {
    display:flex;
    flex-flow: column nowrap;
    align-items: flex-end;
}
.react-tel-input .form-control {
    background: #fafafa !important;
    width: 100%;
    border-bottom: 1px solid rgb(134, 132, 132) !important;
    margin: 0 0 15px !important;
    box-sizing: border-box;
  }

  .react-tel-input .flag-dropdown {
    top: -10px;
    background-color: #fafafa !important;
    border: 0;
}

.reservation-content {
    margin: 40px auto;
    display:flex;
    flex-flow: column;
}

.reservation-content .price-input .title {
    font-weight: 900;
}

.reservation-content .item-name {
    margin-right: 25px;
}
.reservation-content .item-type {
    margin-top: 15px;
    flex-flow: row nowrap;
}

.reservation-content .add-more-button {
    width: fit-content;
    min-width: 0;
    padding: 2px 10px;
    border-radius: 50%;
    font-size: large;
    opacity: 0.9;
    margin: 5px auto;
}
.reservation-content .add-more-button:hover {
    opacity: 1;
}