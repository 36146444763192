.login-page {
  margin: auto;
}
.form {
  position: relative;
  z-index: 1;
  background: #FAFAFA;
  margin: 0 auto 100px;
  padding: 15px 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}
.form input {
  font-family: "Roboto", sans-serif;
  outline: 0;
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}
.form button.submit {
  font-family: "Roboto", sans-serif;
  text-transform: uppercase;
  outline: 0;
  background: #3f51b5;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #FFFFFF;
  font-size: 14px;
  -webkit-transition: all 0.3 ease;
  transition: all 0.3 ease;
  cursor: pointer;
}
.form button.disabled {
  opacity: 0.5;
}

.form button.submit:hover,.form button.submit:active,.form button.submit:focus {
  background: #3f51b5;
}
.form .message {
  margin: 15px 0 0;
  color: #b3b3b3;
  font-size: 12px;
}
.form .message button.link {
  color: #3f51b5;
  vertical-align: inherit;
  margin-left: 3px;
  text-decoration: none;
}

.form .message button.link:hover{
  cursor: pointer !important;
}

.form .error {
  color: red;
}

.form .info-message {
  margin: 15px 0 0;
  color: #3f51b5;
  font-weight: bold;
}

.react-tel-input .form-control {
  background: #f2f2f2;
  width: 100%;
  border: 0;
  margin: 0 0 15px !important;
  box-sizing: border-box;
}

.react-tel-input .flag-dropdown {
  top: -10px;
  background-color: #fff;
  border: 0;
}

.register input {
  padding: 10px 15px;
  border-radius: 5px;
}