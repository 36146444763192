
  .photo-container .photo {
    position: relative;
  }
  .photo-container .photo .caption {
    padding: 5px;
    text-align: center;
    background-color: #ffffff;
    margin-bottom: 20px;
  }

  .photo-container .photo .overlay {
    position: absolute; 
    bottom: 130px; 
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.5); /* Black see-through */
    color: #f1f1f1; 
    width: 95%;
    margin-left: 2.5%;
    transition: .5s ease;
    border-radius: 10px;
    opacity:0;
    color: white;
    padding: 5px;
    text-align: center;
  }
  .photo-container .photo:hover .overlay {
    opacity: 1;
  }
  .photo-container .photo .top-right {
    position: absolute;
    top: 8px;
    right: 1px;
  }
  .photo-container .photo .top-right .edit-icon, .delete-icon {
    border-radius: 50%;
    background-color: #f9f5f5;
    margin-right: 8px;
    opacity: 0.8;
    z-index: 900;
  }
  .photo-container .photo .top-right .edit-icon, .delete-icon:hover {
    cursor: pointer;
  }
  .photo-container .cover {
    color: #ffffff;
    background: grey;
    padding: 3px;
    border-radius: 10px;
    width:fit-content;
    margin: 0 0 3px 10px;
  }
  .photo-container .add-photo-button {
    text-align: center;
    margin: auto;
    width: fit-content;
    border-radius: 50%;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid rgb(242, 240, 240);
    z-index: 900;
  }
  .photo-container .add-photo-button:hover {
    cursor: pointer;
  }
  .photo-container .add-photo-button label:hover {
    cursor: pointer;
  }
  .photo-container .add-photo-button input:hover {
    cursor: pointer;
  } 
  .photo-container .add-photo-button .add-icon {
    display: block;
    margin: auto;
  }
  .photo-container .description {
   display: flex;
   flex-flow: row; 
   justify-content: space-between;
  }
