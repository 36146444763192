.reservation-list {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 20px 5px;    
}

.reservation-list .title {
    font-weight: 900;
    margin-bottom: 10px;
}

.reservation-list .filter {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 20px;
}

.reservation-list .no-reservation {
    color: red;
}

.reservation-list .reservation {
    margin-bottom: 20px;
}

.reservation-list .reservation .item {
    margin-bottom: 5px;
}

.reservation-list .reservation .item .subtitle {
    font-weight: 600;
}

.reservation-list .reservation .item .status {
    text-decoration: line-through;
}