.invoice-container {
    padding: 20px 10px;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
}
.invoice-container > * {
    margin: 20px 0;
}

.flex-column-spacing {
    display: flex;
    justify-content: space-between;
}

.bold {
    font-weight: bold;
}
