html {
    -webkit-text-size-adjust: none
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  /* On smaller screens, decrease text size */
@media only screen and (max-width: 340px) {
  .text-font-x-small {
    font-size: xx-small !important;
  }
  .text-font-small {
    font-size: x-small !important;
  }
  .text-font-medium {
    font-size: small !important;
  }
  .text-font-big {
    font-size: medium;
  }
}

  @media only screen and (min-width: 340px) {
    .text-font-x-small {
      font-size: x-small !important;
    }
    .text-font-small {
      font-size: small !important;
    }
    .text-font-medium {
      font-size: medium !important;
    }
    .text-font-big {
      font-size: large !important;
    }
  }
    
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
.yc-container {
    display: flex;
    flex-wrap: nowrap;
    height: 100%;
    background-color: inherit;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    margin: auto;
    z-index: 10;
}

a.nostyle:link {
    text-decoration: inherit;
    color: inherit;
}

a.nostyle:visited {
    text-decoration: inherit;
    color: inherit;
}

.hidden {
    display: none;
}

.red {
  color: red;
  text-decoration: line-through;
}
.bold {
  font-weight: 700;
}

img {
  vertical-align: middle;
  border-radius: 10px;
}

.link-style {
    color: blue;
}
.link-style:hover {
    cursor: pointer;
}

.odd {
  background-color: rgb(223, 221, 221);
}

.even {
  background-color: rgb(252, 250, 250);
}

.close {
  color: red;
    font-weight: 700;
    text-align: right;
    width:fit-content;
    position: relative;
    top: -10px;
    left: 99.9%;
}
.close:hover {
  cursor: pointer;
  opacity: 0.8;
}

